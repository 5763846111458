/* Style the body */
@font-face{
  font-family:opensans-regular;
  src:url(/public/fonts/OpenSans-Regular.ttf) format("truetype")
}
@font-face{
  font-family:opensans-bold;
  src:url(/public/fonts/OpenSans-Bold.ttf) format("truetype")
}
body {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  background-color: rgb(255, 255, 255);
}
/* Style the container */
.headerHolder {
  background-color: #ffffff;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 7;
}
.headerHolder .header {
  display: flex;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin: 12px auto 0px auto;
}
.headerHolder .header .img {  
  padding: 5px 0px;
  margin-bottom: 0px;
}
.headerHolder .b2l_mobile_border {  
  max-width: 1280px;
  width: 100%;
  height: 4px;  
}
.headerHolder .header .img:first-child {
  width: 165px;
  height: 45px;
}
.headerHolder .header .img:last-child {
  width: auto;
  height: 36px;
}
.page_container {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  background-color: #fff;  
}
.paddingLR20 {padding: 0px 20px;}
.bodyContainer {margin-top: 100px;}
.container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
.lp-orange-title {
  font-family: "opensans-bold",Arial,Helvetica,sans-serif!important;
  font-size: 26px;
  line-height: 36px;
  color: #ff7900;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.thumbnail-container {
  margin-top: 30px;
}

.thumbnailgrid {
	max-width: 1920px;
	width: 100%;
	/* display: table; */
	vertical-align: middle;
	text-align: center;
	margin: auto;
	position:relative;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}
.thumbnailgrid .block-bn-pin {
	background: #fff none repeat scroll 0 0;
  border-radius: 6px;
  width: 180px;
  max-width: 180px;
  min-width: 160px;
  position: relative;
	margin: 8px;
  cursor: pointer;
  float: left;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
}
.thumbnailgrid .block-bn-pin:hover,
.thumbnailgrid .block-bn-pin.block-bn-pinSelect {box-shadow: 0px 0px 4px 0px rgba(255, 121, 0, 1);}
.thumbnailgrid .block-bn-pin .block-bn-pin-innerCont {margin:5px;}
.thumbnailgrid .block-bn-pin .bookTitle {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  font-weight: normal;
  border-bottom: 1px solid #dfdfdf;
  padding: 4px 0px 2px 0px;
  margin: 0px 0px 10px 0px;
  word-break: break-all;
  height: 35px;
  /* display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; */

  
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.thumbnailgrid .block-bn-pin .authorName {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: #404040;
  font-weight: normal;
  border-top: 1px solid #dfdfdf;
  padding: 0px 0px;
  margin: 8px 0px 0px 0px;
  word-break: break-all;
  height: 38px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.thumbnailgrid .pubLogoDiv {
    max-height: 162px;
    max-width: 162px;
    overflow: hidden;
    margin: auto;
}
.thumbnailgrid .pubLogoDiv .pubLogo {
    background-color: #f1f1f1;
    border-radius: 6px;
    display: table-cell;
    font-size: 6px;
    height: 180px;
    max-height: 180px;
    max-width: 180px;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    width: 180px;
}
.thumbnailgrid .pubLogo img {
	  border: medium none;
    height: auto;
    max-height: 180px;
    max-width: 180px;
    width: auto;
}

.custTable-responsive {overflow-x: auto;}
.custTable-responsive::-webkit-scrollbar {height: 8px;}
.custTable-responsive::-webkit-scrollbar-track{background-color: #f1f1f1;}
.custTable-responsive::-webkit-scrollbar-thumb {
    background-color: #ff7900;
    border-radius: 5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
}
.custTable-responsive::-webkit-scrollbar-thumb:hover {background-color: #ff7900;}

.select-container {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #404040;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
  /* float: left;
  width: 39%; */
  margin-top: 20px;
  margin-bottom: 20px;
}
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.question {
  /* display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 50px; */
  /* float: left; */
  width: 100%;
}
.input {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #404040;
  width: calc(100% - 20px);
  height: 80px;  
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  resize: none;
}
.answer {  
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #404040;
}
.output {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #404040;
  width: calc(100% - 20px);  
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  resize: none;
}
.output .ansTitle {
  margin: 0px 0px 15px 0px;
}
.output .ansDec {
  margin: 0px 0px 0px 0px;
}
.select {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #404040;
  width: 500px;  
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
  background: transparent;
}
/* 
.button {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
  cursor: pointer;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #000000;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
}

.button:hover {
  color: #e8e8e8;
}

.button:hover::before {
  width: 100%;
} */

.lp-btn-submit, .lp-btn-delete {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  width: 110px;
  display: inline-block;
  padding: 5px 45px 5px 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25)
}

.lp-btn-submit {
  background: #ff7900 url('/public/b2l_btn_right_arrow.png') no-repeat 75px center
}

.lp-btn-delete {
  background: #9a9a9a url('/public/b2l_btn_delete.png') no-repeat 75px center
}

.lp-btn-submit:hover,.lp-btn-delete:hover,.lp-btn-share:hover {
  opacity: .8
}