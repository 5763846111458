/* Style the body */
@font-face{
  font-family:opensans-regular;
  src:url("~/public/fonts/OpenSans-Regular.ttf") format("truetype")
}
@font-face{
  font-family:opensans-bold;
  src:url("~/public/fonts/OpenSans-Bold.ttf") format("truetype")
}
body {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  background-color: rgb(255, 255, 255);
}
.loginScreen {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;  
  background-color: #222222;
}
.headerHolder {
  background-color: #ffffff;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 7;
}
.headerHolder .header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1920px;
  width: 100%;
  margin: 0px auto;
}
.headerHolder .header .img {  
  padding: 0px;
  margin-bottom: 0px;
}
.headerHolder .b2l_mobile_border {  
  max-width: 1920px;
  width: 100%;
  height: 4px;  
}
.headerHolder .header .img:first-child {
  width: 165px;
  height: 45px;
}
.headerHolder .header .img:last-child {
  width: auto;
  height: 36px;
}
.headerHolder .login-borderB2L {
  background: url("/public/b2l_border.png") repeat-x;
  height: 4px;
  position: relative;
  top: 0px;
}
.login-logo {
  background-color: #ffffff;
  padding: 5px 0px;
}
.login-borderB2L {
  background: url("/public/b2l_border.png") repeat-x;
  height: 4px;
  position: relative;
  top: 5px;
}
.HundredMioLogin {
  width: auto; height: 33px; position: relative; left: 5px; top: 1px; margin: auto;
}
.login-form {
  max-width: 400px;
  width: 100%;
  background-color: white;
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.error {
  color: red;
  font-size: 12px;
}
.loginTitle {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #006e6b;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
  text-align: center;
  font-size: 26px;
  font-weight: 300;
  color: #fff;
}
.inputBody {
  padding: 20px;
  background-color: #eaeaec;
}
.inputFooter {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 0px 0px 5px 5px;
}
.form-group {margin-bottom: 15px;}
.form-group .form-control {
    font-family: "opensans-regular", Arial, Helvetica, sans-serif;
    font-size: 14px;
    display: block;
    width: calc(100% - 24px);
    height: 34px;
    padding: 6px 12px;    
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-group .form-control:hover,
.form-group .form-control:focus {outline: none;}
/* .lp-btn-submit {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  width: 110px;
  display: inline-block;
  padding: 5px 45px 5px 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25)
}
.lp-btn-submit {background: #ff7900 url('/public/b2l_btn_right_arrow.png') no-repeat 75px center}
.lp-btn-submit:hover {opacity: .8} */

.lp-btn-submit {
  font-family: "opensans-regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  width: auto;
  display: inline-block;
  padding: 5px 45px 5px 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.25)
}
.lp-btn-submit {background: #ff7900 url('/public/b2l_btn_right_arrow.png') no-repeat 95% center; min-width: 160px;}
.lp-btn-submit:hover {opacity: .8}
.lp-btn-submit:first-child{background-position: 95% center;}
.lp-btn-submit:last-child{background-position: 98% center;}